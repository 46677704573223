import React, { JSXElementConstructor, useMemo } from "react";
import { cx } from "../classnames";
import { Tooltip } from "./Tooltip";

export interface IconButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	icon: JSXElementConstructor<{ className?: string }>;
	size?: "xs" | "sm" | "md" | "lg" | "xl";
	tooltip?: string;
	variant?:
		| "primary"
		| "secondary"
		| "error"
		| "blank-primary"
		| "blank-secondary";
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
	({ icon: Icon, size = "md", variant = "primary", tooltip, ...rest }, ref) => {
		const Button = useMemo(() => {
			return (
				<button
					{...rest}
					ref={ref}
					className={cx(
						"flex items-center justify-center rounded-full transition-all disabled:cursor-not-allowed disabled:opacity-40",
						size === "xs" && "h-6 min-h-6 w-6 min-w-6",
						size === "sm" && "h-8 min-h-8 w-8 min-w-8",
						size === "md" && "h-10 min-h-10 w-10 min-w-10",
						size === "lg" && "h-12 min-h-12 w-12 min-w-12",
						size === "xl" && "h-14 min-h-14 w-14 min-w-14",
						variant === "primary" &&
							"bg-brand text-text-inverse hover:bg-sky-8",
						variant === "error" &&
							"bg-text-error hover:bg-red-8 text-text-primary",
						variant === "secondary" &&
							"text-text-primary bg-surface-hover-1 hover:bg-surface-hover-2",
						variant === "blank-primary" &&
							"text-text-primary hover:bg-surface-hover-1",
						variant === "blank-secondary" &&
							"hover:bg-surface-hover-1 text-text-secondary",
					)}
				>
					<Icon
						className={cx(
							"h-4 w-4",
							size === "lg" || (size === "xl" && "h-5 w-5"),
						)}
					/>
				</button>
			);
		}, [size, variant, Icon]);

		if (tooltip) {
			return <Tooltip tooltip={tooltip}>{Button}</Tooltip>;
		} else {
			return Button;
		}
	},
);
