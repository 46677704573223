import { z } from "zod";
import { ProfileSchema } from "./profile";

export enum ConnectableAccountProvider {
	FACEBOOK = "facebook",
	INSTAGRAM = "instagram",
	GOOGLE = "google",
	STRIPE = "stripe",
	TIKTOK = "tiktok",
	TWITCH = "twitch",
	YOUTUBE = "youtube",
	FACEBOOKPAGE = "facebook-page",
}

export const getProviderDisplayName = (
	provider: ConnectableAccountProvider,
): string => {
	switch (provider) {
		case ConnectableAccountProvider.FACEBOOK:
			return "Facebook";
		case ConnectableAccountProvider.INSTAGRAM:
			return "Instagram";
		case ConnectableAccountProvider.GOOGLE:
			return "Google";
		case ConnectableAccountProvider.STRIPE:
			return "Stripe";
		case ConnectableAccountProvider.TIKTOK:
			return "TikTok";
		case ConnectableAccountProvider.TWITCH:
			return "Twitch";
		case ConnectableAccountProvider.YOUTUBE:
			return "YouTube";
		case ConnectableAccountProvider.FACEBOOKPAGE:
			return "Facebook Page";
	}
};

export enum ConnectableAccountType {
	OAUTH = "oauth",
	CUSTOM = "custom",
}

export class ConnectableAccount {
	readonly provider: ConnectableAccountProvider;
	readonly type: ConnectableAccountType;

	constructor(
		provider: ConnectableAccountProvider,
		type: ConnectableAccountType,
	) {
		this.provider = provider;
		this.type = type;
	}

	public getRedirectPath(): string {
		return `/oauth/${this.provider}/callback`;
	}
}

export const FACEBOOK = new ConnectableAccount(
	ConnectableAccountProvider.FACEBOOK,
	ConnectableAccountType.OAUTH,
);

export const INSTAGRAM = new ConnectableAccount(
	ConnectableAccountProvider.INSTAGRAM,
	ConnectableAccountType.OAUTH,
);

export const GOOGLE = new ConnectableAccount(
	ConnectableAccountProvider.GOOGLE,
	ConnectableAccountType.OAUTH,
);

export const TIKTOK = new ConnectableAccount(
	ConnectableAccountProvider.TIKTOK,
	ConnectableAccountType.OAUTH,
);

export const YOUTUBE = new ConnectableAccount(
	ConnectableAccountProvider.YOUTUBE,
	ConnectableAccountType.OAUTH,
);

export const TWITCH = new ConnectableAccount(
	ConnectableAccountProvider.TWITCH,
	ConnectableAccountType.OAUTH,
);

export const CONNECTABLE_ACCOUNTS_PROFILES = [FACEBOOK];

export const ConnectAccountRequestSchema = z.object({
	provider: z.nativeEnum(ConnectableAccountProvider),
	code: z.string().max(1024),
	connectionType: z.string().optional(),
});
export type ConnectAccountRequest = z.infer<typeof ConnectAccountRequestSchema>;

export const ConnectedAccountSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	type: z.nativeEnum(ConnectableAccountType),
	provider: z.nativeEnum(ConnectableAccountProvider),
	displayName: z.optional(z.string()),
	canDisconnect: z.boolean(),
	platformProfile: z.optional(ProfileSchema),
});
export type ConnectedAccount = z.infer<typeof ConnectedAccountSchema>;

export type Industries = { name: string; isUniversal: boolean }[];

const INDUSTRIES: Industries = [
	{ name: "Art", isUniversal: false },
	{ name: "Automotive", isUniversal: false },
	{ name: "Beauty", isUniversal: false },
	{ name: "Business", isUniversal: false },
	{ name: "Education", isUniversal: false },
	{ name: "Entertainment", isUniversal: false },
	{ name: "Fashion", isUniversal: false },
	{ name: "Fitness", isUniversal: false },
	{ name: "Food", isUniversal: false },
	{ name: "Gaming", isUniversal: false },
	{ name: "Health", isUniversal: false },
	{ name: "Home", isUniversal: false },
	{ name: "Music", isUniversal: false },
	{ name: "Outdoors", isUniversal: false },
	{ name: "Parenthood", isUniversal: false },
	{ name: "Pets", isUniversal: false },
	{ name: "Sports", isUniversal: false },
	{ name: "Tech", isUniversal: false },
	{ name: "Toys", isUniversal: false },
	{ name: "Travel", isUniversal: false },
];

INDUSTRIES.sort((a, b) => a.name.localeCompare(b.name));

export { INDUSTRIES };
