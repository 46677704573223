import { z } from "zod";
import { ConnectedAccountSchema } from "./connected-account";
import { CustomProfilePreviewSchema, CustomProfileSchema } from "./profile";

export const EmailPreferencesSchema = z.object({
	updates: z.boolean().default(true),
	tips: z.boolean().default(true),
	newsletter: z.boolean().default(true),
});

export const NotificationPreferencesSchema = z.object({
	email: EmailPreferencesSchema,
});
export type NotificationPreferences = z.infer<
	typeof NotificationPreferencesSchema
>;

export const parseNotificationsOrDefault = (obj: unknown) => {
	const parsed = NotificationPreferencesSchema.safeParse(obj);
	if (parsed.success) {
		return parsed.data;
	} else {
		return {
			email: {
				updates: true,
				tips: true,
				newsletter: true,
			},
		};
	}
};

export const UpdateEmailPreferencesSchema = z.object({
	email: z.string().toLowerCase().email().min(3).max(256),
	preferences: EmailPreferencesSchema,
});
export type UpdateEmailPreferences = z.infer<
	typeof UpdateEmailPreferencesSchema
>;

export const ResetPasswordSchema = z.object({
	token: z.string().min(1).max(1024),
	newPassword: z.string().min(3).max(1024),
});
export type ResetPasswordRequest = z.infer<typeof ResetPasswordSchema>;

export const UserFlagsSchema = z.object({
	hasSeenPaymentsAnnouncement: z.boolean().default(false),
	automaticSignIn: z.boolean().optional(),
});
export type UserFlags = z.infer<typeof UserFlagsSchema>;

export const UpdateUserFlagsSchema = UserFlagsSchema.partial();
export type UpdateUserFlags = z.infer<typeof UpdateUserFlagsSchema>;

export const UsernameSchema = z
	.string()
	.min(2)
	.max(64)
	.regex(
		/[A-Z|a-z|0-9|_]+/,
		"Please use only letters, numbers, and underscores.",
	);
export type Username = z.infer<typeof UsernameSchema>;

export const UpdateUserSchema = z.object({
	firstName: z.string().min(1).max(256),
	lastName: z.string().min(1).max(256),
	phoneNumber: z.optional(z.string().max(256)),
	zipCode: z.optional(z.string().max(16)),
	hasCompletedOnboarding: z.optional(z.boolean()),
	getEmailOnMatchUpdate: z.optional(z.boolean()),
	newPassword: z.optional(z.string().min(3).max(1024)),
	notificationPreferences: NotificationPreferencesSchema.optional(),
	profilePictureUuid: z.string().uuid().optional(),
});
export type UpdateUserRequest = z.infer<typeof UpdateUserSchema>;

export const UpdateAgencyUserSchema = z.object({
	creatorUuid: z.string().uuid(),
	firstName: z.string().min(1).max(256),
	lastName: z.string().max(256),
	displayName: z.string().nullable(),
	email: z.string().email(),
	pronouns: z.string().nullable(),
});
export type UpdateAgencyUser = z.infer<typeof UpdateAgencyUserSchema>;

export const CreatorProfileFlagsSchema = z.object({
	promptForPayment: z.optional(z.boolean()),
});

export const CreatorProfileSchema = z.object({
	uuid: z.string().uuid(),
	pitchCredits: z.number(),
	companyWishlistCredits: z.number(),
	contractAnalysisCredits: z.number(),
	connectedAccounts: z.array(ConnectedAccountSchema),
	flags: CreatorProfileFlagsSchema,
	username: z.string().optional(),
	industries: z.array(z.string()),
	pronouns: z.string().nullable(),
	user: z.object({
		uuid: z.string().uuid(),
		firstName: z.string(),
		lastName: z.string(),
		displayName: z.string().nullable(),
		email: z.string().email(),
		hasCompletedOnboarding: z.boolean(),
		notificationPreferences: NotificationPreferencesSchema,
		getEmailOnMatchUpdate: z.boolean(),
		profilePicture: z
			.object({
				uuid: z.string().uuid(),
				url: z.string().url(),
			})
			.optional(),
	}),
	isAutomaticPitchingEnabled: z.boolean(),
	automaticPitchTemplate: z
		.object({ uuid: z.string().uuid(), name: z.string(), emoji: z.string() })
		.optional(),
	tags: z.array(z.string()),
	exclusivity: z.enum(["exclusive", "non-exclusive"]),
	customProfiles: z.array(CustomProfilePreviewSchema),
});
export type CreatorProfile = z.infer<typeof CreatorProfileSchema>;

export const SignInEmailSchema = z.object({
	email: z.string().toLowerCase().email().max(512),
	isOnboarding: z.boolean().default(false),
	next: z.string().optional(),
	bwb: z.string().optional(),
});
export type SignInEmail = z.infer<typeof SignInEmailSchema>;

export const ExchangeEmailAuthTokenSchema = z.object({
	token: z.string().min(1).max(1024),
});
export type ExchangeEmailAuthToken = z.infer<
	typeof ExchangeEmailAuthTokenSchema
>;

export const CreatorSignupSchema = z.object({
	type: z.literal("creator"),
	email: z.string().toLowerCase().email().min(1).max(255),
	firstName: z.string().min(1).max(255),
	lastName: z.string().min(1).max(255),
	displayName: z.string().optional(),
	subscribe: z.boolean().default(true),
	creatorProfile: z.object({
		username: UsernameSchema,
	}),
	referrer: z.string().optional(),
	next: z.string().optional(),
	bwb: z.string().optional(),
});
export type CreatorSignup = z.infer<typeof CreatorSignupSchema>;

export const BusinessSignupSchema = z.object({
	type: z.literal("business"),
	email: z.string().toLowerCase().email().min(1).max(255),
	firstName: z.string().min(1).max(255),
	lastName: z.string().min(1).max(255),
	companyName: z.string().min(1).max(255),
	next: z.string().optional(),
});
export type BusinessSignup = z.infer<typeof BusinessSignupSchema>;

export const AgencySignupSchema = z.object({
	type: z.literal("agency"),
	username: UsernameSchema,
	email: z.string().toLowerCase().email().min(1).max(255),
	firstName: z.string().min(1).max(255),
	lastName: z.string().min(1).max(255),
	name: z.string().min(1).max(256),
	next: z.string().optional(),
});
export type AgencySignup = z.infer<typeof AgencySignupSchema>;

export const AgentSignupSchema = z.object({
	type: z.literal("agent"),
	email: z.string().toLowerCase().email().min(1).max(255),
	firstName: z.string().min(1).max(255),
	lastName: z.string().min(1).max(255),
	agencyId: z.number(),
	next: z.string().optional(),
});
export type AgentSignup = z.infer<typeof AgentSignupSchema>;

export const SignupSchema = z.union([
	CreatorSignupSchema,
	BusinessSignupSchema,
	AgencySignupSchema,
	AgentSignupSchema,
]);
export type Signup = z.infer<typeof SignupSchema>;

export const GoogleOAuthKeySchema = z.object({
	kid: z.string(),
});
export type GoogleOAuthKey = z.infer<typeof GoogleOAuthKeySchema>;

export const GoogleOAuthTokenSchema = z.object({
	email_verified: z.boolean(),
	email: z.string().toLowerCase().email(),
	name: z.string(),
	given_name: z.string(),
	family_name: z.string(),
	aud: z.string(),
});
export type GoogleOAuthToken = z.infer<typeof GoogleOAuthTokenSchema>;
