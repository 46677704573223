import { Combobox as HUCombobox } from "@headlessui/react";
import React from "react";

export interface ComboboxOptionProps<T> {
	children: React.ReactNode;
	value: T;
	disabled?: boolean;
}

export const ComboboxOption = <T,>({
	children,
	value,
	disabled,
}: ComboboxOptionProps<T>) => {
	return (
		// @ts-expect-error emotion causes this to type check incorrectly
		<HUCombobox.Option
			className="relative cursor-pointer select-none px-6 py-1 hover:bg-gray-800"
			value={value as any}
			disabled={disabled}
		>
			{children}
		</HUCombobox.Option>
	);
};
