import {
	ChangeEvent,
	JSXElementConstructor,
	PropsWithChildren,
	forwardRef,
	useMemo,
	useState,
} from "react";
import { Label } from "./Label";
import { IconButton } from "./IconButton";
import {
	Check,
	Eye,
	EyeClosed,
	MagnifyingGlass,
	X,
} from "@withjuly/julycons/bold";
import { CaretDown } from "@withjuly/julycons/fill";
import { cx } from "../classnames";
import { Tooltip } from "./Tooltip";
import * as Popover from "@radix-ui/react-popover";
import { ScrollArea } from "./ScrollArea";

export interface BaseInputProps {
	name?: string;
	size?: "md" | "lg" | "xl";
	error?: boolean;
	errorMessage?: string;
	label?: string;
	tooltip?: string;
	placeholder?: string;
	description?: string;
	rounded?: boolean;
	value?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	className?: string;
	type?: "text" | "number";
	maxLength?: number;
	autofocus?: boolean;
}

interface TextInputProps extends BaseInputProps {
	variant?: "text";
	leadingIcon?: JSXElementConstructor<{ className?: string }>;
	trailingIcon?: JSXElementConstructor<{ className?: string }>;
}

interface PasswordInputProps extends BaseInputProps {
	variant: "password";
}

interface SearchInputProps extends BaseInputProps {
	variant: "search";
	onClear?: () => void;
}

interface TooltipInputProps extends BaseInputProps {
	variant: "tooltip";
	inlineTooltip: string;
}

interface DropdownInputOption {
	icon?: JSXElementConstructor<{ className?: string }>;
	display: string;
	value: string;
}

interface DropdownInputProps extends BaseInputProps {
	variant: "dropdown";
	options?: DropdownInputOption[];
	onOptionSelected?: (value: string) => void;
	leadingIcon?: JSXElementConstructor<{ className?: string }>;
}

interface UrlInputProps extends BaseInputProps {
	variant: "url";
	url: string;
}

export type InputProps =
	| SearchInputProps
	| PasswordInputProps
	| TextInputProps
	| DropdownInputProps
	| TooltipInputProps
	| UrlInputProps;

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(otherProps, ref) => {
		const {
			name,
			size = "md",
			variant,
			error,
			errorMessage,
			label,
			tooltip,
			description,
			rounded,
			placeholder,
			value,
			onChange,
			disabled,
			className,
			type,
			maxLength,
			autofocus,
			...rest
		} = otherProps;

		const [showPassword, setShowPassword] = useState(() => false);
		const [showDropdown, setShowDropdown] = useState(() => false);
		const [showClear, setShowClear] = useState(() => value && value !== "");

		const LeadingIcon =
			variant === undefined || variant === "text" || variant === "dropdown"
				? otherProps.leadingIcon ?? null
				: null;

		const TrailingIcon =
			variant === undefined || variant === "text"
				? otherProps.trailingIcon ?? null
				: null;

		const Wrapper: React.FC<PropsWithChildren> = useMemo(() => {
			return ({ children }) => {
				if (variant === "dropdown") {
					return <Popover.Trigger asChild={true}>{children}</Popover.Trigger>;
				} else {
					return <>{children}</>;
				}
			};
		}, [variant]);

		return (
			<Popover.Root open={showDropdown} modal={false}>
				<div className="font-repro group flex w-full flex-col gap-2">
					{label ? (
						<Label
							size="xs"
							tooltip={tooltip}
							variant="overline"
							color="secondary"
						>
							{label}
						</Label>
					) : null}
					<Wrapper>
						<div
							className={cx(
								"focus-within:border-brand bg-slate-alpha-2 relative flex items-center justify-center gap-2 border-[2px] border-transparent",
								disabled && "opacity-40 hover:cursor-not-allowed",
								rounded && "rounded-full",
								!rounded && "rounded-solis-md",
								size === "md" && "h-10 pl-3 pr-2",
								size === "lg" && "h-12 pl-[18px] pr-[10px]",
								size === "xl" && "h-14 pl-[18px] pr-[10px]",
								error && "border-red-7 focus-within:border-red-7",
								variant === "url" && "gap-0",
								className,
							)}
						>
							{variant === "search" ? (
								<MagnifyingGlass className="h-4 min-h-4 w-4 min-w-4" />
							) : null}
							{LeadingIcon ? (
								<LeadingIcon className="text-text-secondary h-4 min-h-4 w-4 min-w-4" />
							) : null}
							{variant === "url" ? (
								<p className="text-text-secondary text-paragraph-md">
									{otherProps.url}
								</p>
							) : null}
							<input
								name={name}
								maxLength={maxLength}
								type={
									variant === "password" && !showPassword
										? "password"
										: type ?? "text"
								}
								className="placeholder:text-text-placeholder text-paragraph-md h-5 w-full bg-transparent focus:outline-none focus:ring-0 disabled:cursor-not-allowed "
								placeholder={placeholder}
								ref={ref}
								value={value}
								onChange={(e) => {
									if (variant === "search") {
										if (e.target.value !== "") {
											setShowClear(() => true);
										} else {
											setShowClear(() => false);
										}
									}
									if (variant === "dropdown") {
										if (!showDropdown) {
											setShowDropdown(true);
										}
									}
									onChange?.(e);
								}}
								disabled={disabled}
								onFocus={() => {
									if (variant === "dropdown") {
										setShowDropdown(() => true);
									}
								}}
								autoFocus={autofocus}
								onBlur={() => {
									if (variant === "dropdown") {
										setTimeout(() => {
											setShowDropdown(() => false);
										}, 300);
									}
								}}
							/>
							{TrailingIcon ? (
								<TrailingIcon className="text-text-secondary h-4 min-h-4 w-4 min-w-4" />
							) : null}
							{variant === "password" ? (
								<IconButton
									icon={showPassword ? EyeClosed : Eye}
									size="sm"
									variant="blank-secondary"
									onClick={() => setShowPassword((prev) => !prev)}
								/>
							) : null}
							{variant === "search" && showClear ? (
								<IconButton
									icon={X}
									size="sm"
									variant="blank-secondary"
									onClick={() => {
										setShowClear(() => false);
										otherProps.onClear?.();
									}}
								/>
							) : null}
							{variant === "dropdown" ? (
								<CaretDown
									className={`h-4 w-4 ${
										otherProps.options && otherProps.options.length > 0
											? "group-focus-within:rotate-180"
											: ""
									} transform`}
								/>
							) : null}
							{variant === "tooltip" ? (
								<Tooltip tooltip={otherProps.inlineTooltip} />
							) : null}
						</div>
					</Wrapper>
					{description ? (
						<p className="text-text-tertiary text-paragraph-xs">
							{description}
						</p>
					) : null}
					{error ? (
						<p className="text-red-7 text-paragraph-xs">{errorMessage}</p>
					) : null}
				</div>
				{otherProps.variant === "dropdown" ? (
					<Popover.Portal className="z-[300] w-full">
						<Popover.Content
							onOpenAutoFocus={(e) => e.preventDefault()}
							onCloseAutoFocus={(e) => e.preventDefault()}
							onPointerDownOutside={() => {
								setShowDropdown(() => false);
							}}
							className={cx(
								"bg-surface-primary border-stroke-primary rounded-solis-md z-[200] flex w-[var(--radix-popover-trigger-width)] transform flex-col border data-[side=bottom]:translate-y-2 data-[side=top]:-translate-y-2",
								(otherProps.options === undefined ||
									otherProps.options?.length === 0) &&
									"hidden",
							)}
						>
							<ScrollArea className="h-64 max-h-64 w-full overflow-y-scroll">
								{otherProps.options?.map((option, i) => {
									return (
										<button
											key={`${i}-${option.value}`}
											className="border-b-stroke-tertiary hover:bg-surface-hover-1 flex h-10 w-full items-center justify-between border-b-[0.5px] px-3 transition-colors last:border-0"
											onClick={(e) => {
												e.preventDefault();
												otherProps.onOptionSelected?.(option.value);
												setShowDropdown(() => false);
											}}
										>
											<div className="flex items-center gap-2">
												{option.icon ? <option.icon /> : null}
												{option.display}
											</div>
											{otherProps.value === option.value ? <Check /> : null}
										</button>
									);
								})}
							</ScrollArea>
						</Popover.Content>
					</Popover.Portal>
				) : null}
			</Popover.Root>
		);
	},
);
