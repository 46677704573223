import * as Dialog from "@radix-ui/react-dialog";
import React, { PropsWithChildren, ReactNode, useMemo } from "react";
import { X } from "@withjuly/julycons/bold";
import { cx } from "../classnames";
import { Button, ButtonProps } from "./Button";
import { motion } from "framer-motion";
import { Checkbox } from "./Checkbox";
import { ScrollArea } from "./ScrollArea";

export interface RootProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	size?: "sm" | "md" | "lg" | "xl" | "screen";
	className?: string;
	isModal?: boolean;
}

export const Root: React.FC<PropsWithChildren<RootProps>> = ({
	isOpen,
	setIsOpen,
	size = "md",
	isModal = true,
	children,
	className,
}) => {
	return (
		<Dialog.Root modal={isModal} open={isOpen} onOpenChange={setIsOpen}>
			<Dialog.Portal>
				{isModal ? (
					<Dialog.Overlay className="font-repro bg-sky-1/75 fixed inset-0 z-[30] h-screen w-screen data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards] data-[state=open]:animate-[fade-in-no-scale_250ms_ease-in-out]" />
				) : (
					<div
						onClick={() => setIsOpen(false)}
						className="font-repro bg-sky-1/75 pointer-events-auto fixed inset-0 z-[20] h-screen w-screen data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards] data-[state=open]:animate-[fade-in-no-scale_250ms_ease-in-out]"
					/>
				)}
				<Dialog.Content
					className={cx(
						"group fixed left-1/2 top-1/2 z-[30] -translate-x-1/2 -translate-y-1/2 transform rounded-[20px]",
						className,
					)}
				>
					<motion.div
						data-size={size}
						initial={{
							width:
								size === "sm"
									? "352px"
									: size === "md"
										? "480px"
										: size === "lg"
											? "640px"
											: size === "xl"
												? "800px"
												: size === "screen"
													? "100%"
													: "auto",
							scale: size === "screen" ? 0.9 : 0.5,
						}}
						transition={{
							duration: 0.15,
							type: "just",
						}}
						exit={{
							scale: size === "screen" ? 0.9 : 0.5,
						}}
						animate={{
							width:
								size === "sm"
									? "352px"
									: size === "md"
										? "480px"
										: size === "lg"
											? "640px"
											: size === "xl"
												? "800px"
												: size === "screen"
													? "100%"
													: "auto",
							scale: 1.0,
						}}
						className="bg-surface-primary border-stroke-primary overflow-clip rounded-[20px] border"
					>
						{children}
					</motion.div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

type HeaderProps =
	| {
			title: string;
			description?: ReactNode;
	  }
	| {
			title: undefined;
			description: undefined;
	  };

export const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
	({ title, description }, ref) => {
		return (
			<div
				className="font-repro border-b-stroke-tertiary relative flex w-full items-start justify-between px-8 py-6 first:border-b"
				ref={ref}
			>
				<div className="flex w-full flex-col items-start gap-2">
					<p
						className={cx(
							"group-data-[size=sm]:text-header-lg text-header-xl",
							title === undefined && "hidden",
						)}
					>
						{title}
					</p>
					<p
						className={cx(
							"text-paragraph-sm h-fit opacity-[64%]",
							description === undefined && "hidden",
						)}
					>
						{description}
					</p>
				</div>
				<Dialog.Close className="hover:bg-surface-hover-1 absolute right-4 top-4 rounded-full p-3 transition-colors">
					<X className="h-full w-full" color="#ffffff" />
				</Dialog.Close>
			</div>
		);
	},
);

interface BodyProps {
	className?: string;
}

export const Body: React.FC<PropsWithChildren<BodyProps>> = ({
	className,
	children,
}) => {
	return (
		<ScrollArea
			className={cx(
				"rounded-inherit h-full max-h-[60vh] w-full px-8 py-6",
				className,
			)}
		>
			{children}
		</ScrollArea>
	);
};

interface BaseFooterProps {
	variant?: "primary" | "danger" | "secondary";
	layout?: "grouped" | "separated";
	primaryLabel: string;
	onPrimaryClicked: () => void;
	onSecondaryClicked?: ButtonProps["onClick"];
	isPrimaryLoading?: boolean;
	isPrimaryDisabled?: boolean;
}

interface PrimaryButtonsFooterProps extends BaseFooterProps {
	buttons?: "primary";
}

interface PrimarySecondaryButtonsFooterProps extends BaseFooterProps {
	buttons: "primary-secondary";
	secondaryLabel: string;
	onSecondaryClicked: ButtonProps["onClick"];
}

interface PrimaryCheckFooterProps extends BaseFooterProps {
	buttons: "primary-check";
	checkLabel: string;
	isChecked: boolean;
	onChecked: (isChecked: boolean) => void;
}

export type FooterProps =
	| PrimaryButtonsFooterProps
	| PrimarySecondaryButtonsFooterProps
	| PrimaryCheckFooterProps;

export const Footer: React.FC<FooterProps> = (props) => {
	const secondaryInput = useMemo(() => {
		if (props.buttons === "primary-secondary") {
			return (
				<Button variant="secondary" onClick={props.onSecondaryClicked}>
					{props.secondaryLabel}
				</Button>
			);
		} else if (props.buttons === "primary-check") {
			return (
				<Checkbox
					isChecked={props.isChecked}
					onChecked={props.onChecked}
					label={props.checkLabel}
				/>
			);
		} else {
			return null;
		}
	}, [props]);

	return (
		<div
			className={cx(
				"border-t-stroke-tertiary flex w-full justify-end gap-2 border-t px-8 py-6",
				props.layout === "separated" &&
					(props.buttons ?? "primary") !== "primary" &&
					"justify-between",
			)}
		>
			{secondaryInput}
			<Button
				variant={props.variant}
				onClick={props.onPrimaryClicked}
				disabled={props.isPrimaryDisabled}
				isLoading={props.isPrimaryLoading}
			>
				{props.primaryLabel}
			</Button>
		</div>
	);
};
