import type { AppProps } from "next/app";
import Head from "next/head";
import { PostHogProvider } from "posthog-js/react";
import { useInitPosthog } from "~/utils/hooks/posthog";
import { GoogleAnalytics } from "~/components/Utility/GoogleAnalytics";
import posthog from "posthog-js";
import { NextPageWithLayout } from "~/utils/next";
import "~/styles/globals.css";
import "focus-visible/dist/focus-visible";
import "~/utils/sentry";
import { SolisProvider } from "@withjuly/solis";
import { QueryProvider } from "../components/Utility/QueryProvider";
import localFont from "next/font/local";
import { Inter } from "next/font/google";
import { ToastProvider } from "@withjuly/solisv2";
import { DeckStoreProvider } from "~/utils/context/deck";
import { RosterStoreProvider } from "~/utils/context/roster";

const agrandir = localFont({
	src: "../../public/fonts/PPAgrandirText-Bold.woff2",
	fallback: ["sans-serif"],
});

const inter = Inter({
	subsets: ["latin"],
	weight: ["300", "400", "500", "600", "700"],
	fallback: [
		"SF Pro Display",
		"-apple-system",
		"BlinkMacSystemFont",
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Open Sans",
		"Helvetica Neue",
		"sans-serif",
	],
});

const repro = localFont({
	src: [
		{
			path: "../../public/fonts/ABCRepro-Screen.woff2",
			weight: "350",
			style: "normal",
		},
		{
			path: "../../public/fonts/ABCRepro-Medium.woff2",
			weight: "500",
			style: "normal",
		},
	],
});

const App = ({
	Component,
	pageProps,
}: AppProps & {
	Component: NextPageWithLayout;
}) => {
	useInitPosthog();

	return (
		<SolisProvider>
			<ToastProvider />
			<PostHogProvider client={posthog}>
				<QueryProvider>
					<DeckStoreProvider>
						<RosterStoreProvider>
							<Head>
								<title key="title">July</title>
								<link
									rel="apple-touch-icon"
									sizes="180x180"
									href="/favicon/apple-touch-icon.png"
								/>
								<link
									rel="icon"
									type="image/png"
									sizes="32x32"
									href="/favicon/favicon-32x32.png"
								/>
								<link
									rel="icon"
									type="image/png"
									sizes="16x16"
									href="/favicon/favicon-16x16.png"
								/>
								<link rel="shortcut icon" href="/favicon/favicon.ico" />
								<meta name="theme-color" content="#0D0D0D" />
								<meta
									content="width=device-width,initial-scale=1,maximum-scale=1"
									name="viewport"
								/>
							</Head>

							<style jsx global>{`
								html {
									--font-agrandir: ${agrandir.style.fontFamily};
									--font-inter: ${inter.style.fontFamily};
									--font-repro: ${repro.style.fontFamily};
								}

								body {
									font-family: var(--font-inter);
								}
							`}</style>

							<GoogleAnalytics />
							{Component.getLayout(<Component {...pageProps} />)}
						</RosterStoreProvider>
					</DeckStoreProvider>
				</QueryProvider>
			</PostHogProvider>
		</SolisProvider>
	);
};

export default App;
