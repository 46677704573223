import { Combobox as HUCombobox } from "@headlessui/react";
import React from "react";

export interface ComboboxOptionsProps {
	children: React.ReactNode;
}

export const ComboboxOptions = ({ children }: ComboboxOptionsProps) => {
	return (
		// @ts-expect-error emotion causes this to type check incorrectly
		<HUCombobox.Options className="shadow-100 absolute z-10 mt-1 max-h-64 w-full list-none overflow-auto rounded-lg border border-gray-200 bg-gray-500">
			{children}
		</HUCombobox.Options>
	);
};
