import { z } from "zod";

export enum Platform {
	FACEBOOK = "facebook",
	INSTAGRAM = "instagram",
	TIKTOK = "tiktok",
	TWITCH = "twitch",
	EMAIL = "email",
	YOUTUBE = "youtube",
	INSTAGRAM_NOAUTH = "instagram-noauth",
	YOUTUBE_NOAUTH = "youtube-noauth",
	TIKTOK_NOAUTH = "tiktok-noauth",
}

export const getPlatformDisplayName = (platform: Platform): string => {
	switch (platform) {
		case Platform.FACEBOOK:
			return "Facebook";
		case Platform.INSTAGRAM:
		case Platform.INSTAGRAM_NOAUTH:
			return "Instagram";
		case Platform.TIKTOK:
		case Platform.TIKTOK_NOAUTH:
			return "TikTok";
		case Platform.TWITCH:
			return "Twitch";
		case Platform.EMAIL:
			return "Email";
		case Platform.YOUTUBE:
		case Platform.YOUTUBE_NOAUTH:
			return "YouTube";
	}
};

export const PlatformPost = z.object({
	likeCount: z.number().optional(),
	commentsCount: z.number().optional(),
	shareCount: z.number().optional(),
	mediaUrl: z.string().url().optional(),
	postUrl: z.string().url(),
	base64: z.string().optional(),
});
export type PlatformPost = z.infer<typeof PlatformPost>;

export const PlatformPostsSchema = z.array(PlatformPost);
export type PlatformPosts = z.infer<typeof PlatformPostsSchema>;

const ProfileDemographicsSchema = z.object({
	male: z.record(z.number()),
	female: z.record(z.number()),
	countries: z.record(z.number()),
	gender: z
		.object({
			male: z.number(),
			female: z.number(),
		})
		.optional(),
	age: z
		.object({
			"13-17": z.number(),
			"18-24": z.number(),
			"25-34": z.number(),
			"35-44": z.number(),
			"45-54": z.number(),
			"55-64": z.number(),
			"65+": z.number(),
		})
		.partial(),
});
export type ProfileDemographics = z.infer<typeof ProfileDemographicsSchema>;

export const PlatformStatsSchema = z.object({
	platform: z.nativeEnum(Platform),
	platformUrl: z.string().url().optional(),
	averageLikes: z.number().optional(),
	averageComments: z.number().optional(),
	averageViews: z.number().optional(),
	totalViews: z.number().optional(),
	averageShares: z.number().optional(),
	size: z.number().optional(),
	demographics: ProfileDemographicsSchema.optional(),
	engagementRate: z.number().optional(),
	username: z.string().optional(),
	displayName: z.string().optional(),
	averageLiveViews: z.number().optional(),
});
export type PlatformStats = z.infer<typeof PlatformStatsSchema>;

export const ProfileSchema = z.object({
	uuid: z.string(),
	createdAt: z.date(),
	updatedAt: z.date(),

	platform: z.nativeEnum(Platform),
	isActive: z.boolean(),
	isPending: z.boolean(),
	username: z.string(),
	displayName: z.optional(z.string()),
	url: z.optional(z.string().url()),
	isMatchPoolLow: z.boolean().default(false),

	size: z.optional(z.number()),
	engagement: z.optional(z.number()),
	gender: z.optional(z.string()),

	industries: z.array(z.string()),
	profileUrl: z.string().optional(),

	demographics: ProfileDemographicsSchema.optional(),
	stats: PlatformStatsSchema.optional(),

	recentPosts: PlatformPostsSchema,

	hasIssue: z.boolean(),
});
export type Profile = z.infer<typeof ProfileSchema>;

export const getProfileDisplayName = (profile: Profile): string => {
	switch (profile.platform) {
		case Platform.FACEBOOK:
			return profile.displayName ?? profile.username;
		case Platform.INSTAGRAM:
		case Platform.INSTAGRAM_NOAUTH:
			return `@${profile.username}`;
		case Platform.TIKTOK:
		case Platform.TIKTOK_NOAUTH:
			return `@${profile.username}`;
		case Platform.EMAIL:
			return profile.displayName ?? profile.username;
		case Platform.YOUTUBE:
		case Platform.YOUTUBE_NOAUTH:
			return `${profile.username}`;
		case Platform.TWITCH:
			return `${profile.username}`;
	}
};

export const UpdateMatchingPreferencesSchema = z.object({
	size: z.number().gte(0),
	engagement: z.number().gte(0),
	gender: z.string().max(64),
});
export type UpdateMatchingPreferencesRequest = z.infer<
	typeof UpdateMatchingPreferencesSchema
>;

export const UpdatePlatformProfileSchema = z.object({
	displayName: z.optional(z.string().max(512)),
	url: z.optional(z.string().url().max(1024)),
	isActive: z.boolean(),
	matchingPreferences: UpdateMatchingPreferencesSchema,
});
export type UpdatePlatformProfileRequest = z.infer<
	typeof UpdatePlatformProfileSchema
>;

const dateSchema = z.preprocess((arg) => {
	if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date());

export const CachedInstagramPost = z.object({
	likeCount: z.number(),
	commentsCount: z.number(),
	timestamp: dateSchema,
	mediaUrl: z.string().url().optional(),
	thumbnailUrl: z.string().url().optional(),
	postUrl: z.string().url(),
	mediaType: z.string(),
});
export type CachedInstagramPost = z.infer<typeof CachedInstagramPost>;
export const CachedInstagramPosts = z.object({
	expiresAt: dateSchema,
	fetchedAt: dateSchema,
	posts: z.array(CachedInstagramPost),
});
export type CachedInstagramPosts = z.infer<typeof CachedInstagramPosts>;

export const ContentStatSchema = z.object({
	name: z.string(),
	displayTitle: z.string(),
	value: z.number(),
});

export const InstagramStoryPostDataSchema = z.object({
	type: z.literal("instagram story"),
	stats: ContentStatSchema.array(),
});
export type InstagramStoryPostData = z.infer<
	typeof InstagramStoryPostDataSchema
>;

export const InstagramMetadata = z.object({
	demographics: z
		.object({
			updatedAt: dateSchema,
			country: z.record(z.number()).optional(),
			genderAge: z.record(z.number()).optional(),
		})
		.optional(),
	stories: z
		.object({
			receivedAt: dateSchema,
			mediaId: z.string(),
			impressions: z.number(),
			reach: z.number(),
		})
		.array()
		.optional(),
	impressions: z.number().optional(),
	weekImpressions: z.number().optional(),
	bio: z.string().optional(),
	profilePic: z
		.object({
			url: z.string().url(),
			expiresAt: dateSchema,
			fetchedAt: dateSchema,
		})
		.optional(),
});
export type InstagramMetadata = z.infer<typeof InstagramMetadata>;

export const CachedTikTokPost = z.object({
	likeCount: z.number().default(0),
	commentsCount: z.number().default(0),
	shareCount: z.number().default(0),
	viewCount: z.number().default(0),
	timestamp: dateSchema,
	mediaUrl: z.string().url(),
	postUrl: z.string().url(),
});
export type CachedTikTokPost = z.infer<typeof CachedTikTokPost>;

export const CachedTikTokPosts = z.object({
	expiresAt: dateSchema,
	fetchedAt: dateSchema,
	posts: z.array(CachedTikTokPost),
});
export type CachedTikTokPosts = z.infer<typeof CachedTikTokPosts>;

export const TikTokMetaDataSchema = z.object({
	accountUrl: z.string().url(),
	isVerified: z.boolean(),
	demographics: z
		.object({
			updatedAt: dateSchema.optional(),
			country: z.record(z.number()).optional(),
			gender: z
				.object({
					male: z.number(),
					female: z.number(),
				})
				.optional(),
			age: z.record(z.number()).optional(),
		})
		.optional(),
});
export type TikTokMetaData = z.infer<typeof TikTokMetaDataSchema>;

export const YouTubeMetadata = z.object({
	channelTitle: z.string(),
	channelId: z.string(),
	viewCount: z.number().optional(),
	averageWatchTime: z.number(),
	demographics: z.object({
		gender: z.object({
			male: z.array(
				z.object({
					percentage: z.number(),
					ageRange: z.array(z.number()),
				}),
			),
			female: z.array(
				z.object({
					percentage: z.number(),
					ageRange: z.array(z.number()),
				}),
			),
			other: z.array(
				z.object({
					percentage: z.number(),
					ageRange: z.array(z.number()),
				}),
			),
		}),
		country: z.record(z.number()),
	}),
	accountUrl: z.string().optional(),
});
export type YouTubeMetadata = z.infer<typeof YouTubeMetadata>;

export const YouTubePostSchema = z.object({
	viewCount: z.number().optional(),
	likeCount: z.number().optional(),
	commentCount: z.number().optional(),
	duration: z.number().optional(),
	id: z.string().optional(),
	thumbnailUrl: z.string().url().optional(),
	publishedAt: dateSchema.optional(),
});
export type YouTubePost = z.infer<typeof YouTubePostSchema>;

export const YouTubePosts = z.object({
	expiresAt: dateSchema,
	fetchedAt: dateSchema,
	posts: z.array(YouTubePostSchema),
});
export type YouTubePosts = z.infer<typeof YouTubePosts>;

export const PlatformSizeClassSchema = z.enum([
	"Nano",
	"Micro",
	"Mid",
	"Macro",
	"Mega",
	"Premium",
]);
export type PlatformSizeClass = z.infer<typeof PlatformSizeClassSchema>;

export enum EngagementTiers {
	One = "1",
	Two = "2",
	Three = "3",
	Four = "4",
	Five = "5",
}
export const PlatformEngagementTierSchema = z.nativeEnum(EngagementTiers);
export type PlatformEngagementTier = z.infer<
	typeof PlatformEngagementTierSchema
>;

export const CountryDataSchema = z.object({
	country: z.string().optional(),
	percentage: z.string().optional(),
});
export type CountryData = z.infer<typeof CountryDataSchema>;

export const CountryDataFormSchema = z.object({
	firstCountry: CountryDataSchema,
	secondCountry: CountryDataSchema,
	thirdCountry: CountryDataSchema,
	fourthCountry: CountryDataSchema,
	fifthCountry: CountryDataSchema,
});
export type CountryDataForm = z.infer<typeof CountryDataFormSchema>;

export const GenderDataFormSchema = z.object({
	male: z.string(),
	female: z.string(),
});
export type GenderDataForm = z.infer<typeof GenderDataFormSchema>;

export const AgeDataFormSchema = z.object({
	"18-24": z.string(),
	"25-34": z.string(),
	"35-44": z.string(),
	"45-54": z.string(),
	"55+": z.string(),
});

export const PlatformProfileAnalytics = z.object({
	followers: z.number().optional(),
});

export const InstagramGenderAndAgeResonseSchema = z.object({
	total_value: z.object({
		breakdowns: z.array(
			z.object({
				dimension_keys: z.tuple([z.literal("age"), z.literal("gender")]),
				results: z.array(
					z.object({
						dimension_values: z.tuple([z.string(), z.string()]),
						value: z.number(),
					}),
				),
			}),
		),
	}),
});
export type InstagramGenderAndAgeResponse = z.infer<
	typeof InstagramGenderAndAgeResonseSchema
>;

export const InstagramLocationResponseSchema = z.object({
	total_value: z.object({
		breakdowns: z.array(
			z.object({
				dimension_keys: z.tuple([z.literal("country")]),
				results: z.array(
					z.object({
						dimension_values: z.tuple([z.string()]),
						value: z.number(),
					}),
				),
			}),
		),
	}),
});
export type InstagramLocationResponse = z.infer<
	typeof InstagramLocationResponseSchema
>;

export const TwitchMetaDataSchema = z.object({
	url: z.string().url().optional(),
	hoursWatched: z.number().optional(),
	highestViews: z.number().optional(),
	averageLiveViews: z.number().optional(),
	totalLiveViews: z.number().optional(),
	uniqueViews: z.number().optional(),
	dailyAverageViews: z.number().optional(),
	averageViewDuration: z.number().optional(),
	dailyChatEngagementRate: z.number().optional(),
	expiresAt: dateSchema,
});
export type TwitchMetaData = z.infer<typeof TwitchMetaDataSchema>;

export const TwitchPostSchema = z.object({
	id: z.string().optional(),
	thumbnailUrl: z.string().url().optional(),
	url: z.string().url().optional(),
	title: z.string().optional(),
	viewCount: z.number().optional(),
});
export type TwitchPost = z.infer<typeof TwitchPostSchema>;

export const TwitchPosts = z.object({
	expiresAt: dateSchema,
	fetchedAt: dateSchema,
	posts: z.array(TwitchPostSchema),
});
export type TwitchPosts = z.infer<typeof TwitchPosts>;

export const CachedFacebookPost = z.object({
	timestamp: dateSchema,
	likeCount: z.number(),
	commentCount: z.number(),
	shareCount: z.number(),
	reactionCount: z.number(),
	postUrl: z.string().url(),
	mediaUrl: z.string().url(),
});
export type CachedFacebookPost = z.infer<typeof CachedFacebookPost>;
export const CachedFacebookPosts = z.object({
	expiresAt: dateSchema,
	fetchedAt: dateSchema,
	posts: z.array(CachedFacebookPost),
});
export type CachedFacebookPosts = z.infer<typeof CachedFacebookPosts>;

export const FacebookMetadata = z.object({
	impressions: z.number().optional(),
	weekImpressions: z.number().optional(),
	accountUrl: z.string().url(),
	pageLikes: z.number().optional(),
	updatedAt: dateSchema,
	demographics: z.object({
		country: z.record(z.number()).optional(),
	}),
});
export type FacebookMetadata = z.infer<typeof FacebookMetadata>;

export const ProfileContentPostType = z.enum(["instagram story"]);

export const ProfileContentPostDataSchema = z.discriminatedUnion("type", [
	InstagramStoryPostDataSchema,
]);

export const SerializedProfileContent = z.object({
	uuid: z.string(),
	createdAt: z.date(),
	updatedAt: z.date(),
	postedAt: z.date(),

	postData: ProfileContentPostDataSchema,

	postId: z.string(),
	postType: ProfileContentPostType,
	mediaUrl: z.string().optional(),
});
export type SerializedProfileContent = z.infer<typeof SerializedProfileContent>;

export const CustomProfileStatSchema = z.object({
	name: z.string(),
	displayTitle: z.string().min(2),
	value: z.union([z.number(), z.string()]),
	enabled: z.boolean(),
});

export type CustomProfileStat = z.infer<typeof CustomProfileStatSchema>;

export const CustomContentImageSize = z.enum([
	"square",
	"vertical",
	"horizontal",
]);
export type CustomContentImageSizeType = z.infer<typeof CustomContentImageSize>;

export const CustomProfileContentSchema = z.object({
	uuid: z.string(),
	imageUrl: z.string().url(),
	imageSize: CustomContentImageSize,
	contentUrl: z.string().url(),
	description: z.string().optional(),
	order: z.number(),
});

export type CustomProfileContent = z.infer<typeof CustomProfileContentSchema>;

export const UpsertCustomProfileContentSchema = z.object({
	uuid: z.string().optional(),
	customProfileUuid: z.string(),
	mediaUuid: z.string(),
	content: z.object({
		imageSize: CustomContentImageSize,
		contentUrl: z.string().url(),
		description: z.string().optional(),
		order: z.number().default(0),
	}),
});

export const CustomProfileDemographicsSchema = z.object({
	country: z.record(z.number()).optional(),
	gender: z
		.object({
			male: z.number(),
			female: z.number(),
		})
		.optional(),
	age: z.record(z.number()).optional(),
});

export type CustomProfileDemographics = z.infer<
	typeof CustomProfileDemographicsSchema
>;

export const CustomProfileMetadataSchema = z.object({
	demographics: CustomProfileDemographicsSchema.optional(),
	stats: z.array(CustomProfileStatSchema).optional(),
});

export type CustomProfileMetadata = z.infer<typeof CustomProfileMetadataSchema>;

export const CustomProfilePreviewSchema = z.object({
	uuid: z.string(),
	title: z.string(),
	isActive: z.boolean(),
	username: z.string().nullable(),
});

export type CustomProfilePreview = z.infer<typeof CustomProfilePreviewSchema>;

export const CustomProfileSchema = z.object({
	uuid: z.string(),
	createdAt: z.date(),
	updatedAt: z.date(),

	title: z.string(),
	isActive: z.boolean(),
	username: z.string().optional(),
	accountUrl: z.string().url().optional(),

	metadata: CustomProfileMetadataSchema.optional(),
	content: z.array(CustomProfileContentSchema).optional(),
});

export type CustomProfile = z.infer<typeof CustomProfileSchema>;

export const CreateCustomProfileSchema = z.object({
	creatorProfileUuid: z.string(),
	username: z.string().optional(),
	title: z.string().min(2),
	accountUrl: z.string().url().or(z.literal("")),
});

export const UpdateCustomProfileSchema = z.object({
	uuid: z.string(),
	title: z.string().min(2),
	username: z.string().optional(),
	accountUrl: z.string().url().or(z.literal("")),
	metadata: z
		.object({
			stats: z.array(CustomProfileStatSchema).optional(),
			demographics: CustomProfileDemographicsSchema.optional(),
		})
		.optional(),
});

export const UpdateProfileActivationSchema = z.object({
	profileUuid: z.string(),
	isActive: z.boolean(),
});

export const UpdateCustomProfileCountryDataSchema = z.object({
	uuid: z.string().uuid(),
	data: CountryDataFormSchema,
	creatorUuid: z.string().uuid(),
});

export const UpdateCustomProfileAgeDataSchema = z.object({
	uuid: z.string().uuid(),
	data: AgeDataFormSchema,
	creatorUuid: z.string().uuid(),
});

export const UpsertCustomProfileGenderDataSchema = z.object({
	uuid: z.string().uuid(),
	data: GenderDataFormSchema,
	creatorUuid: z.string().uuid(),
});

export const UpsertCustomProfileContentOrderSchema = z.object({
	content: z.array(
		z.object({
			uuid: z.string().uuid(),
			order: z.number(),
		}),
	),
});
