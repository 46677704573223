import { Combobox as HUCombobox } from "@headlessui/react";
import React from "react";

export interface ComboboxButtonProps {
	children: React.ReactNode;
}
export const ComboboxButton: React.FC<ComboboxButtonProps> = ({ children }) => {
	return (
		// @ts-expect-error emotion causes this to type check incorrectly
		<HUCombobox.Button style={{ width: "100%" }}>{children}</HUCombobox.Button>
	);
};
