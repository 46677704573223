import { Combobox as HUCombobox } from "@headlessui/react";
import React from "react";

export interface ComboboxProps<T> {
	input: React.ReactNode;
	options: React.ReactNode;

	selected: T;
	setSelected: (selected: T) => void;
}

export const Combobox = <T,>({
	input,
	options,
	selected,
	setSelected,
}: ComboboxProps<T>) => {
	return (
		// @ts-expect-error emotion causes this to type check incorrectly
		<HUCombobox value={selected} onChange={setSelected}>
			<div className="relative w-full">
				{input}
				{options}
			</div>
		</HUCombobox>
	);
};
