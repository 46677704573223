import { z } from "zod";

export const CreatorCardSection = z.enum([
	"about",
	"stats",
	"rates",
	"partnerships",
]);
export type CreatorCardSection = z.infer<typeof CreatorCardSection>;

export const UpdateCardSectionVisibility = z.object({
	deckUuid: z.string().uuid(),
	section: CreatorCardSection,
	show: z.boolean(),
});
export type UpdateCardSectionVisibility = z.infer<
	typeof UpdateCardSectionVisibility
>;

export const CreateDeckForm = z.object({
	title: z.string(),
	description: z.string().optional(),
});
export type CreatkDeckForm = z.infer<typeof CreateDeckForm>;
