import { z } from "zod";

export const AgencyBillingStatusSchema = z.enum([
	"active",
	"incomplete",
	"incomplete_expired",
	"trialing",
	"past_due",
	"canceled",
	"unpaid",
]);
export type AgencyBillingStatus = z.infer<typeof AgencyBillingSchema>;

export const AgencyBillingSchema = z.object({
	status: AgencyBillingStatusSchema,
	cardLastFour: z.string().nullable(),
	expiration: z.string().nullable(),
	nextChargeDate: z.date().nullable(),
});
export type AgencyBilling = z.infer<typeof AgencyBillingSchema>;

export const BillingReceiptSchema = z.object({
	paidAt: z.date().nullable(),
	total: z.number(),
	status: z.enum(["draft", "open", "paid", "uncollectible", "void", "deleted"]),
	url: z.string().url().nullable().optional(),
});
export type BillingReceipt = z.infer<typeof BillingReceiptSchema>;

export const MostRecentReceiptsSchema = BillingReceiptSchema.array().nullable();
export type MostRecentReceipts = z.infer<typeof MostRecentReceiptsSchema>;
